import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common"; // Importez DatePipe depuis @angular/common
import { MatListModule } from "@angular/material/list";
import { BrowserModule } from "@angular/platform-browser";
import { StoreModule } from "@ngrx/store";
import { PlotlyViaWindowModule } from "angular-plotly.js";
import { ChartModule } from "angular2-chartjs";
import { NotifierModule } from "angular-notifier";
import { ChartsModule } from "ng2-charts";
import { AppComponent } from "./app.component";
import { ChartComponent } from "./components/charts/chart/chart.component";
import { LabelComponent } from "./components/charts/label/label.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { KpiComponent } from "./components/pages/kpi/kpi.component";
import { DetailComponent } from "./components/pages/list/detail/detail.component";
import { HeaderListComponent } from "./components/pages/list/header-list/header-list.component";
import { ListComponent } from "./components/pages/list/list.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { MapDetailComponent } from "./components/pages/map/detail/map.detail.component";
import { MapComponent } from "./components/pages/map/map.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { MaterialDesignModule } from "./material-design.components";
import { RoutingModule } from "./routing/routing.module";
import { AuthGuard } from "./services/auth-guard.service";
import { XlsxExporter } from "./services/xlsx-exporter";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { ContainerTypesReducer } from "./stores/data_store/containers-type-store";
import { StatusReducer } from "./stores/data_store/status-store";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import "leaflet-textpath";
import { SimpleDialogComponent } from "./components/dialog/simple-dialog.component";
import { CustomDate, CustomDateComment, DateNullable } from "./pipes/date-pipe";
import { SlashToLineBreak } from "./pipes/slash-line-break.pipe";
import { CustomRight } from "./pipes/custom-right-pipe";
import { ObjectTransform } from "./pipes/object-transform-pipe";

import { GeoUtils } from "./services/geo-utils";

import { ClipboardModule } from "@angular/cdk/clipboard";

import { FiltersControllerService } from "./services/filters.service";
import { AnalyticsControllerService } from "./services/analytics-controller.service";
import { AuthInterceptor } from "./services/auth-interceptor.service";
import { ContainerTypeAuthSiteControllerService } from "./services/container-type-auth-site-controller.service";
import { ContainerTypeControllerService } from "./services/container-type-controller.service";
import { DataDecompressorService } from "./services/data-decompressor.service";
import { DeviceContainerControllerService } from "./services/device-container-controller.service";
import { DeviceContainerStateControllerService } from "./services/device-container-state-controller.service";
import { ExternalUserControllerService } from "./services/external-user-controller.service";
import { IncidentHistoricalControllerService } from "./services/incident-historical-controller.service";
import { InternalUserControllerService } from "./services/internal-user-controller.service";
import { MacAddressControllerService } from "./services/mac-address-controller.service";
import { PolyvalentControllerService } from "./services/polyvalent-controller.service";
import { ProfileContainerTypeControllerService } from "./services/profile-container-type-controller.service";
import { ReferenceNameControllerService } from "./services/reference-name-controller.service";
import { RightContainerTypeControllerService } from "./services/right-container-types-controller.service";
import { RightControllerService } from "./services/right-controller.service";
import { SiteControllerService } from "./services/site-controller.service";
import { SitePostalControllerService } from "./services/site-postal-controller.service";
import { UserControllerService } from "./services/user-controller.service";
import { WebSocketService } from "./services/web-socket.service";
import { CustomMatPaginatorIntl } from "./services/custom-mat-paginator-intl";
import { ProvisioningControllerService } from "./services/provisionning-controller.service";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";

import { HeaderKpiComponent } from "./components/pages/kpi/header-kpi/header-kpi.component";
import { HttpClientWrapper } from "./services/http-client.wrapper";

import { HeaderMapComponent } from "./components/pages/map/header-map/header-map.component";

import { BatteryLabelComponent } from "./components/charts/battery-label/battery-label.component";
import { BatteryComponent } from "./components/pages/kpi/battery/battery.component";
import { ContainerTypeSidenavComponent } from "./components/sidenav/container-type-sidenav/container-type-sidenav.component";

import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { DragScrollModule } from "ngx-drag-scroll";

import { CommonModule } from "@angular/common";
import { AnalyticsComponent } from "./components/pages/analytics/analytics.component";
import { SiteSidenavComponent } from "./components/sidenav/site-sidenav/site-sidenav.component";
import { StateDisplayerComponent } from "./components/utils/state-displayer/state-displayer.component";
import { StatePopupComponent } from "./components/utils/state-popup/state-popup.component";

import { InfiniteScrollDirective } from "./directives/infinite-scroll.directive";
import { focusDirective } from "./directives/focus.directive";

import { PortalModule } from "@angular/cdk/portal";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DeviceOffContractManagerComponent } from "./components/pages/manager/device-off-contract-manager/device-off-contract-manager.component";
import { ManagerComponent } from "./components/pages/manager/manager.component";
import { MatchingComponent } from "./components/pages/manager/matching/matching.component";
import { ProfileManagerComponent } from "./components/pages/manager/profile-manager/profile-manager.component";
import { ReferenceManagerComponent } from "./components/pages/manager/reference-manager/reference-manager.component";
import { UserManagerComponent } from "./components/pages/manager/user-manager/user-manager.component";
import { FileUploaderComponent } from "./components/pages/manager/utils/file-uploader/file-uploader.component";
import { UploadListElementComponent } from "./components/pages/manager/utils/file-uploader/upload-list-element/upload-list-element.component";
import { ListElementComponent } from "./components/pages/manager/utils/list-element/list-element.component";
import { ManagerFormComponent } from "./components/pages/manager/utils/manager-form/manager-form.component";
import { ManagerListComponent } from "./components/pages/manager/utils/manager-list/manager-list.component";
import { ReferenceTypeComponent } from "./components/pages/manager/utils/reference-type/reference-type.component";
import { ExportExcelComponent } from "./components/utils/export-excel/export-excel.component";
import { ToggleCheckboxComponent } from "./components/utils/toggle-checkbox/toggle-checkbox.component";
import { DeviceOffContractControllerService } from "./services/device-off-contract-controller.service";
import { LangManagerComponent } from "./components/utils/lang-manager/lang-manager.component";
import { IncidentComponent } from "./components/pages/incident/incident.component";
import { IncidentControllerService } from "./services/incident-controller.service";
import { StatusDotsComponent } from "./components/elements/filters-dots/status-dots.component";
import { SearchbarListComponent } from "./components/elements/searchbar-list/search-site-list.component";
import { FilterButtonFooterComponent } from "./components/elements/filter-button/filter-button-footer/filter-button-footer.component";
import { IncidentSidenavComponent } from "./components/sidenav/incident-sidenav/incident-sidenav.component";
import { ButtonComponent } from "./components/elements/button/button.component";
import { CommentComponent } from "./components/pages/incident/comment/comment.component";
import { IncidentDialogComponent } from "./components/pages/incident/incident-dialog/incident-dialog.component";
import { DeviceComponent } from "./components/pages/incident/device/device.component";
import { IncidentTagComponent } from "./components/pages/incident/incident-tag/incident-tag.component";
import { HeaderAnalyticsComponent } from "./components/pages/analytics/header-analytics/header-analytics.component";
import { DatePickerComponent } from "./components/elements/date-picker/date-picker.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ButtonListSingleSelectComponent } from "./components/elements/button-list-single-select/button-list-single-select.component";
import { DeviceInfoWindowComponent } from "./components/pages/map/detail/device-info-window/device-info-window.component";
import { CopiableDeviceInfoComponent } from "./components/pages/map/detail/copiable-device-info/copiable-device-info.component";
import { PositionControllerComponent } from "./components/pages/map/detail/position-controller/position-controller.component";
import { UploadDialogComponent } from "./components/pages/manager/upload-dialog/upload-dialog.component";
import { CalloutComponent } from "./components/elements/callout/callout.component";
import { RadioButtonComponent } from "./components/elements/radio-button/radio-button.component";
import { DragAndDropDirective } from "./directives/drag-and-drop.directive";
import { SearchbarNumberComponent } from "./components/elements/searchbar-number/searchbar-number.component";
import { GraphsDisplayerComponent } from "./components/pages/analytics/graphs-displayer/graphs-displayer.component";
import { StateDisplayerLabelComponent } from "./components/utils/state-displayer-label/state-displayer-label.component";
import { StateDisplayerStatusComponent } from "./components/utils/state-displayer-status/state-displayer-status.component";
import { StateDisplayerSkeletonLineComponent } from "./components/utils/state-displayer-skeleton-line/state-displayer-skeleton-line.component";
import { StateDisplayerColumnNamesComponent } from "./components/utils/state-displayer-column-names/state-displayer-column-names.component";
import { ColorService } from "./services/color.service";
import { IncidentPopupComponent } from "./components/pages/incident/incident-popup/incident-popup.component";
import { SwitchComponent } from "./components/pages/manager/switch/switch.component";
import { FilterButtonComponent } from "./components/elements/filter-button/filter-button.component";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NotFoundComponent,
    KpiComponent,
    ChartComponent,
    LabelComponent,
    ListComponent,
    HeaderListComponent,
    HeaderKpiComponent,
    MapComponent,
    DetailComponent,
    MapDetailComponent,
    SimpleDialogComponent,
    CustomDate,
    CustomDateComment,
    DateNullable,
    CustomRight,
    ObjectTransform,
    ContainerTypeSidenavComponent,
    HeaderMapComponent,
    BatteryComponent,
    BatteryLabelComponent,
    AnalyticsComponent,
    StateDisplayerComponent,
    StatePopupComponent,
    InfiniteScrollDirective,
    focusDirective,
    SiteSidenavComponent,
    ManagerComponent,
    UserManagerComponent,
    ProfileManagerComponent,
    ListElementComponent,
    ManagerListComponent,
    ManagerFormComponent,
    ReferenceManagerComponent,
    ReferenceTypeComponent,
    FileUploaderComponent,
    UploadListElementComponent,
    MatchingComponent,
    ToggleCheckboxComponent,
    DeviceOffContractManagerComponent,
    ExportExcelComponent,
    LangManagerComponent,
    SlashToLineBreak,
    IncidentComponent,
    StatusDotsComponent,
    SearchbarListComponent,
    FilterButtonFooterComponent,
    IncidentSidenavComponent,
    ButtonComponent,
    CommentComponent,
    IncidentDialogComponent,
    DeviceComponent,
    IncidentTagComponent,
    HeaderAnalyticsComponent,
    DatePickerComponent,
    ButtonListSingleSelectComponent,
    DeviceInfoWindowComponent,
    CopiableDeviceInfoComponent,
    PositionControllerComponent,
    UploadDialogComponent,
    CalloutComponent,
    RadioButtonComponent,
    DragAndDropDirective,
    SearchbarNumberComponent,
    GraphsDisplayerComponent,
    SearchbarNumberComponent,
    StateDisplayerLabelComponent,
    StateDisplayerStatusComponent,
    StateDisplayerSkeletonLineComponent,
    StateDisplayerColumnNamesComponent,
    IncidentPopupComponent,
    SwitchComponent,
    FilterButtonComponent
  ],
  imports: [
    PortalModule,
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    MaterialDesignModule,
    MatButtonToggleModule,
    MatListModule,
    MatTooltipModule,
    MatExpansionModule,
    MatRadioModule,
    ChartsModule,
    ChartModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    FormsModule,
    NotifierModule.withConfig({
      behaviour: {
        autoHide: 12000
      }
    }),
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot({
      ctTypes: ContainerTypesReducer,
      statusStore: StatusReducer
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LeafletModule.forRoot(),
    DragScrollModule,
    NgxSliderModule,
    CommonModule,
    PlotlyViaWindowModule,
    MatSlideToggleModule,
    AngularSvgIconModule.forRoot(),
    ClipboardModule,
    MatDialogModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    AuthGuard,
    XlsxExporter,
    GeoUtils,
    DatePipe,
    ContainerTypeControllerService,
    FiltersControllerService,
    AnalyticsControllerService,
    SiteControllerService,
    ContainerTypeAuthSiteControllerService,
    InternalUserControllerService,
    ExternalUserControllerService,
    MacAddressControllerService,
    PolyvalentControllerService,
    SitePostalControllerService,
    DeviceContainerControllerService,
    DeviceContainerStateControllerService,
    DeviceOffContractControllerService,
    UserControllerService,
    HttpClientWrapper,
    IncidentHistoricalControllerService,
    ProfileContainerTypeControllerService,
    RightContainerTypeControllerService,
    RightControllerService,
    ReferenceNameControllerService,
    DataDecompressorService,
    WebSocketService,
    IncidentControllerService,
    ColorService,
    ProvisioningControllerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

<notifier-container></notifier-container>
<app-header-analytics
  (days)="manageDays($event)"
  (sites)="manageSites($event)"
  (dates)="manageDates($event)"
  (graphic)="manageGraphic($event)"
  (containers)="manageContainers($event)"
  (arrivalSites)="manageArrivalSites($event)"
  (departureSites)="manageDepartureSites($event)"
  [graphNames]="graphNames"
  [graphConfig]="selectedGraphConfig"
  [filterDays]="filterDays"
  [filterSites]="filterSites"
  [filterDateEnd]="filterDateEnd"
  [filterDateStart]="filterDateStart"
  [filterContainers]="filterContainers"
  [filterArrivalSites]="filterArrivalSites"
  [filterDepartureSites]="filterDepartureSites"
></app-header-analytics>
<div class="analytics-title" *ngIf="loading">
  {{ "misc.loading" | translate }}
  <img class="analytics-title-loading" src="/assets/img/spinner-sites.gif" />
</div>
<div
  class="analytics-title"
  *ngIf="(!filterGraphic || !hasData) && !loading && !hasError"
>
  {{
    (!filterGraphic ? "analytics.selectGraph" : "analytics.selectContainerType")
      | translate
  }}
</div>
<div class="analytics-title" *ngIf="!hasData && !loading && hasError">
  {{ errorMsg }}
</div>

<app-graphs-displayer
  *ngIf="hasData && !loading"
  [datas]="datas"
  [config]="selectedGraphConfig"
  [containerTypes]="filterContainers"
></app-graphs-displayer>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output
} from "@angular/core";
import * as moment from "moment/moment";
import { CurrentStateDTO } from "../../../models/current-state";
import { DeviceContainerStateControllerService } from "../../../services/device-container-state-controller.service";
import { GeoUtils } from "../../../services/geo-utils";
import { UserRightsService } from "../../../services/user-rights.service";
import { IncidentControllerService } from "../../../services/incident-controller.service";
import { IncidentDTO } from "../../../models/incident";
import { STATE } from "../../utils/export-excel/export-excel.component";
import { getBatteryIconName } from "../../../services/get-icon";

export type StateDisplayerType = "Map" | "List" | "ListDetail";

interface DeviceData {
  checked: boolean;
  device: CurrentStateDTO;
}
@Component({
  selector: "app-state-displayer",
  templateUrl: "./state-displayer.component.html",
  styleUrls: ["./state-displayer.component.scss"]
})
export class StateDisplayerComponent implements OnInit, OnChanges {
  @Output() handleDevice: EventEmitter<DeviceData> = new EventEmitter();
  @Output() openCategory: EventEmitter<any> = new EventEmitter();
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentState: CurrentStateDTO;
  @Input() isSelectionOn: boolean;

  @Input() listDetailDisplay: boolean;
  @Input() listDisplay: boolean;
  @Input() mapDisplay: boolean;

  @Input() isChecked: boolean = false;

  public activeAlert: boolean = false;
  public exportType: string = STATE;
  public incidentData: IncidentDTO;
  public currentClass: string;
  public hasNotInit: boolean;
  public momentTime: string;
  public stateIcon: string;

  constructor(
    public _deviceContainerStateService: DeviceContainerStateControllerService,
    public _incidentControllerService: IncidentControllerService,
    public _geoUtils: GeoUtils,
    private _userRightsService: UserRightsService
  ) {
    this.hasNotInit = true;
  }

  ngOnInit() {
    if (this.currentState !== undefined) {
      this.hasNotInit = false;
      this.stateIcon = this._geoUtils.getMarkerIconSmall(
        this.currentState.statusColor,
        this.currentState.statusColor === "GREY",
        this.currentState.locationSource?.toUpperCase().includes("WIFI")
      );
      this.momentTime = moment
        .unix(this.currentState.receivedMessageTime)
        .format("DD/MM/YYYY HH:mm");
      this.setActiveAlert();
      if (this.mapDisplay) {
        this.currentClass = "map";
      } else if (this.listDisplay || this.listDetailDisplay) {
        this.currentClass = "list";
      }
    }
  }

  ngOnChanges(changes) {
    if (
      changes.currentState &&
      !changes.currentState.firstChange &&
      this.currentState
    ) {
      this.momentTime = moment
        .unix(this.currentState.receivedMessageTime)
        .format("DD/MM/YYYY HH:mm");
      this.stateIcon = this._geoUtils.getMarkerIconSmall(
        this.currentState.statusColor,
        this.currentState.statusColor === "GREY",
        this.currentState.locationSource.toUpperCase().includes("WIFI")
      );
      this.setActiveAlert();
    }
    if (changes.isChecked && this.activeAlert) {
      this.handleDevice.emit({
        checked: this.isChecked,
        device: this.currentState
      });
    }
  }

  onChangeTab(info: any) {
    this.navigate.emit({ tab: info.tab, incident: info.incident });
  }

  public redirectToGmap(lat, long): void {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
      "_blank"
    );
  }

  private setActiveAlert(): void {
    this.activeAlert = this.currentState.statusColor !== "GREEN";
  }

  private onOpenCategory(event): void {
    this.openCategory.emit(event);
  }

  protected isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }

  public onHandleDevice(event): void {
    if (this.activeAlert) {
      event.stopPropagation();
      this.updateIncidentDevice();
    }
  }

  public updateIncidentDevice(): void {
    this.isChecked = !this.isChecked;
    this.handleDevice.emit({
      checked: this.isChecked,
      device: this.currentState
    });
  }

  public getBatteryIcon: (batteryLevel: string) => string | undefined =
    getBatteryIconName;

  public getType(): StateDisplayerType {
    if (this.mapDisplay) {
      return "Map";
    } else if (this.listDisplay) {
      return "List";
    } else {
      return "ListDetail";
    }
  }

  public getLastAuthorizedSite(): string {
    if (this.currentState.lastAuthorizedSiteCofor?.length) {
      return this.currentState.lastAuthorizedSiteCofor;
    } else if (this.currentState.lastAuthorizedSite?.length) {
      return this.currentState.lastAuthorizedSite;
    }
    return "-";
  }

  public getTimeLastAuthorizedSite(): string {
    if (
      this.currentState.lastAuthorizedSite?.length &&
      this.currentState.timeLastAuthorizedSite
    ) {
      return moment
        .unix(this.currentState.timeLastAuthorizedSite)
        .format("DD/MM/YYYY HH:mm");
    }
    return "-";
  }

  public getPosition(): string {
    if (this.currentState.currentSiteCofor?.length) {
      return this.currentState.currentSiteCofor;
    } else if (this.currentState.currentSite) {
      return String(this.currentState.currentSite);
    }
    return (
      this.currentState?.latitudeComputed?.toFixed(2) +
      ", " +
      this.currentState?.longitudeComputed?.toFixed(2)
    );
  }

  public getDeviceId(): string {
    return this.currentState.deviceId?.length
      ? this.currentState.deviceId
      : "-";
  }

  public getContainerType(): string {
    return this.currentState.containerType?.length
      ? this.currentState.containerType
      : "-";
  }

  public getSeqNumber(): string {
    return this.currentState.seqNumber
      ? String(this.currentState.seqNumber)
      : "-";
  }

  public getSource(): string {
    return this.currentState.source ?? "-";
  }
}

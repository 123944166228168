import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {
  AppVersion,
  CheckVersioningControllerService
} from "./services/check-versioning.service";
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from "./components/utils/lang-manager/lang-manager.component";
import packageInfo from "../../package.json";
import { IconRegistryService } from "./services/icon-registry.service";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>"
})
export class AppComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  private backVersion: AppVersion;
  private frontVersion: AppVersion = {
    versionKey: "Not assigned"
  };

  constructor(
    public checkVersioningControllerService: CheckVersioningControllerService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private iconRegistry: IconRegistryService
  ) {
    translate.addLangs(AVAILABLE_LANGUAGES);
    translate.setDefaultLang(DEFAULT_LANGUAGE);
    this.initLang();
  }

  public ngOnInit(): void {
    console.log("start app with check version");
    // this.checkVersion();
    this.iconRegistry.loadIcons();
  }

  private initLang(): void {
    let lang: string = localStorage.getItem("pref-lang");

    if (!lang) {
      lang = this.translate.getBrowserLang();
      localStorage.setItem("pref-lang", lang);
    }
    this.translate.use(
      AVAILABLE_LANGUAGES.includes(lang) ? lang : DEFAULT_LANGUAGE
    );
  }

  private refresh(): void {
    console.log("refresh call()");

    // localStorage.clear();
    // window.location.reload();
  }

  private checkVersion(): void {
    let version: string = localStorage.getItem("version");

    if (!version) {
      version = packageInfo.version;
      localStorage.setItem("version", version);
    }

    this.frontVersion.versionKey = version;
    console.log("Application loaded with version: ", version);

    this.compareVersion();
  }

  private compareVersion(): void {
    this.subscription.add(
      this.checkVersioningControllerService
        .getCheckVersioning()
        .subscribe((version) => {
          if (version) {
            this.backVersion = version;

            if (this.backVersion.versionKey !== this.frontVersion.versionKey) {
              this.refresh();
            }
          }
        })
    );
  }
}

import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClientWrapper } from "./http-client.wrapper";
import { Observable } from "rxjs";

export interface IBulkProvisioningJSON {
  [key: string]: string[];
}

export interface IAppProvisioningJSON {
  devices: IBulkProvisioningJSON;
}

export interface IReProvisioningJSON {
  devicesIds: string[];
  rejectedDevice: string[];
  rejectedContainers: string[];
}

@Injectable()
export class ProvisioningControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<IReProvisioningJSON>,
    public httpClientWrapperCode: HttpClientWrapper<string>
  ) {}

  update(data: string): Observable<IReProvisioningJSON> {
    return this.httpClientWrapper.postUnique(
      environment.apiBaseUrl +
        `device-containers/bulk-correcting/reprovisionning`,
      data
    );
  }

  checkContainers(codes: string[]): Observable<string[]> {
    return this.httpClientWrapperCode.post(
      environment.apiBaseUrl +
        `device-containers/bulk-correcting/check-containers`,
      codes
    );
  }
}

<mat-sidenav-container
  class="incident-sidenav-container"
  (backdropClick)="onClose()"
>
  <mat-sidenav
    #incidentSidenav
    mode="over"
    class="incident-sidenav"
    position="end"
  >
    <div class="incident-sidenav-buttons">
      <app-button
        (clicked)="onSubmit()"
        class="incident-create-button"
        color="blue"
        type="filled"
        [labelPath]="'misc.createNew'"
        iconRight="plus"
      ></app-button>
      <app-button
        (clicked)="onClose()"
        class="incident-cancel-button"
        [labelPath]="'misc.cancel'"
      ></app-button>
    </div>
    <div class="incident-sidenav-sections">
      <mat-accordion multi>
        <mat-expansion-panel class="incident-sidenav-section" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title> Information </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            *ngFor="let input of informationInputs"
            [class]="{
              'incident-sidenav-section-item': true,
              'hidden-item': input.hidden
            }"
          >
            <div [class]="{ 'section-item-label': true, error: !input.valid }">
              {{ input.title }}
              <span class="mandatory" *ngIf="input.mandatory">*</span>
            </div>

            <input
              *ngIf="input.input"
              class="section-item-input"
              [type]="input.type"
              [placeholder]="'Enter a ' + input.title"
              [(ngModel)]="incidentCreation[input.property]"
              (ngModelChange)="setIsValid($event, input)"
            />

            <div
              class="section-item-selector"
              *ngIf="input.selector"
              [matMenuTriggerFor]="ItemMenu"
              (menuOpened)="changeArrow(input)"
              (menuClosed)="changeArrow(input)"
            >
              <p>{{ incidentCreation[input.property] }}</p>
              <mat-icon>{{
                input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }}</mat-icon>
              <mat-menu class="selector-menu" #ItemMenu="matMenu">
                <div class="selector-menu-holder">
                  <div
                    *ngFor="let choice of input.choices; first as isFirst"
                    class="selector-menu-choice"
                  >
                    <div
                      class="selector-choice-button"
                      (click)="selectItem(choice, input)"
                    >
                      {{ choice }}
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
            <div
              class="section-item-selector"
              *ngIf="input.search_selector"
              [matMenuTriggerFor]="ItemMenu"
              (menuOpened)="changeArrow(input)"
              (menuClosed)="changeArrow(input)"
            >
              <div class="section-item-selector">
                <input
                  class="search_selector_input"
                  [type]="text"
                  [placeholder]="input.title | translate"
                  [ngModel]="
                    !pipeData ? input.filter : (input.filter | objectTransform)
                  "
                  (ngModelChange)="input.filter = $event"
                  (input)="onFilterChange(input)"
                />
              </div>
              <mat-icon>
                {{ input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
              </mat-icon>
              <mat-menu class="selector-menu" #ItemMenu="matMenu">
                <div class="selector-menu-holder">
                  <div
                    *ngFor="
                      let choice of input.filteredOptions;
                      first as isFirst
                    "
                    class="selector-menu-choice"
                  >
                    <div
                      *ngIf="input.search_selector"
                      class="selector-choice-button"
                      (click)="selectItem(choice, input, filter)"
                    >
                      <span *ngIf="input.property === 'currentSite'">{{
                        choice | objectTransform
                      }}</span>
                      <span *ngIf="input.property !== 'currentSite'">{{
                        choice
                      }}</span>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="incident-sidenav-section" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title> Activity </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            *ngFor="let input of activityInputs"
            [class]="{
              'incident-sidenav-section-item': true,
              'hidden-item': input.hidden
            }"
          >
            <div [class]="{ 'section-item-label': true, error: !input.valid }">
              {{ input.title }}
              <span class="mandatory" *ngIf="input.mandatory">*</span>
            </div>

            <input
              *ngIf="input.input"
              class="section-search-item-input"
              type="text"
              [placeholder]="'Enter a ' + input.title"
              [(ngModel)]="incidentCreation[input.property]"
            />

            <div
              class="section-item-selector"
              *ngIf="input.selector"
              [matMenuTriggerFor]="ItemMenu"
              (menuOpened)="changeArrow(input)"
              (menuClosed)="changeArrow(input)"
            >
              <p>{{ incidentCreation[input.property] }}</p>
              <mat-icon>{{
                input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }}</mat-icon>
              <mat-menu class="selector-menu" #ItemMenu="matMenu">
                <div class="selector-menu-holder">
                  <div
                    *ngFor="let choice of input.choices; first as isFirst"
                    class="selector-menu-choice"
                  >
                    <div
                      class="selector-choice-button"
                      (click)="selectItem(choice, input)"
                    >
                      {{ choice }}
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
            <div
              class="section-item-selector"
              *ngIf="input.search_selector"
              [matMenuTriggerFor]="ItemMenu"
              (menuOpened)="changeArrow(input)"
              (menuClosed)="changeArrow(input)"
            >
              <div class="section-item-selector">
                <input
                  class="search_selector_input"
                  [type]="text"
                  [placeholder]="input.title | translate"
                  [(ngModel)]="input.filter"
                  (input)="onFilterChange(input)"
                />
              </div>
              <mat-icon>
                {{ input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
              </mat-icon>
              <mat-menu class="selector-menu" #ItemMenu="matMenu">
                <div class="selector-menu-holder">
                  <div
                    *ngFor="
                      let choice of input.filteredOptions;
                      first as isFirst
                    "
                    class="selector-menu-choice"
                  >
                    <div
                      *ngIf="input.search_selector"
                      class="selector-choice-button"
                      (click)="selectItem(choice, input, filter)"
                    >
                      {{ choice }}
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="incident-sidenav-section" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title> Comment </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="commentary-container">
            <textarea
              class="commentary-item-textarea"
              placeholder="Add comment"
              [(ngModel)]="commentFromSidenav"
            ></textarea>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
<notifier-container></notifier-container>

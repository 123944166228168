import { Component, Input, OnChanges } from "@angular/core";
import { ReferenceNameControllerService } from "../../../services/reference-name-controller.service";
import { UserRightsService } from "../../../services/user-rights.service";

interface NavObject {
  navName: string;
  isOpen: boolean;
  selectorStyle: string;
  navLogo?: string;
  style?: string;
}
@Component({
  selector: "app-manager",
  templateUrl: "./manager.component.html",
  styleUrls: ["./manager.component.scss"]
})
export class ManagerComponent implements OnChanges {
  //@Input() loading: string;
  @Input() referenceLoading: boolean | undefined;

  private readonly SELECTOR_STYLE_OPEN = "nav-selector-open";
  private readonly SELECTOR_STYLE_CLOSE = "nav-selector-close";
  private readonly NAVIGATOR_STYLE_ENABLED = "navigator";
  private readonly NAVIGATOR_STYLE_DISABLED = "navigator-disabled";

  selectedIndex: number;

  navObject: NavObject[] = [
    {
      navName: "Users",
      isOpen: true,
      selectorStyle: this.SELECTOR_STYLE_OPEN,
      navLogo: "user"
    },
    {
      navName: "Groups",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE,
      navLogo: "group"
    },
    {
      navName: "Reference",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE,
      navLogo: "reference"
    }
  ];

  tokenNavObject: NavObject = {
    navName: "Token",
    isOpen: false,
    selectorStyle: this.SELECTOR_STYLE_CLOSE,
    navLogo: "token"
  };
  // change after
  switchNavObject: NavObject = {
    navName: "Devices",
    isOpen: false,
    selectorStyle: this.SELECTOR_STYLE_CLOSE,
    navLogo: "device"
  };

  selectedNav: string = "Users";

  navStyle: string = this.NAVIGATOR_STYLE_ENABLED;

  constructor(
    public referenceNameControllerService: ReferenceNameControllerService,
    private userRightsService: UserRightsService
  ) {}

  ngOnChanges() {
    this.navStyle = this.referenceLoading
      ? this.NAVIGATOR_STYLE_DISABLED
      : this.NAVIGATOR_STYLE_ENABLED;
  }

  onNavChange(event: NavObject) {
    if (this.referenceLoading === undefined) {
      for (const nav of this.navObject) {
        nav.isOpen = false;
        nav.selectorStyle = this.SELECTOR_STYLE_CLOSE;
      }
      this.tokenNavObject.isOpen = false;
      this.tokenNavObject.selectorStyle = this.SELECTOR_STYLE_CLOSE;
      //to delete after
      this.switchNavObject.isOpen = false;
      this.switchNavObject.selectorStyle = this.SELECTOR_STYLE_CLOSE;
      //here
      event.isOpen = true;
      event.selectorStyle = this.SELECTOR_STYLE_OPEN;
      this.selectedNav = event.navName;
    }
  }

  isIBMAdmin(): boolean {
    return this.userRightsService.getUserRightLevel("IBMAdmin");
  }
}

<div class="buttons" *ngIf="!isForm && !isUploaded">
  <app-button
    class="button"
    [labelPath]="'manager.Reference.switch.switchContainer' | translate"
    (clicked)="triggerForm()"
    type="filled"
  ></app-button>
  <app-button
    class="button"
    [labelPath]="'manager.Reference.switch.upload' | translate"
    type="filled"
    iconRight="upload"
    (clicked)="openDialog('Switch')"
  ></app-button>
  <app-button
    class="button"
    [labelPath]="'manager.Reference.switch.downloadTemplate' | translate"
    type="filled"
    iconRight="download"
    (clicked)="downloadTemplate('Switch')"
  ></app-button>
</div>
<div *ngIf="isForm && !isUploaded" class="form-menu">
  <div *ngIf="isForm" class="form-device-list">
    <div class="form" *ngIf="isForm">
      <div
        class="main-section-item"
        *ngFor="let input of formConfig.inputs; let i = index"
      >
        <div class="label-item">
          <div class="label-text">
            {{ "manager.inputs." + input.title + ".title" | translate }}
          </div>
          <div class="label-input-mandatory" *ngIf="input.mandatory">*</div>
          <div class="property-error" *ngIf="input.valid === false">
            -
            {{
              input.errorMsg
                ? input.errorMsg
                : ("manager.inputs." + input.title + ".errorMsg" | translate)
            }}
          </div>
        </div>
        <div
          [class]="input.valid === false ? 'selector-error' : 'selector-type'"
          *ngIf="input.search_selector"
          [matMenuTriggerFor]="ItemMenu"
          (menuOpened)="changeArrow(input)"
          (menuClosed)="changeArrow(input)"
        >
          <div class="selector">
            <div class="list-box">
              <div class="list-box-label">
                <input
                  class="search_selector_input"
                  *ngIf="input.search_selector"
                  [type]="text"
                  [placeholder]="'Filter ' + (input.title | translate)"
                  [ngModel]="input.filter"
                  (ngModelChange)="input.filter = $event"
                  (input)="onFilterChange(input)"
                />
              </div>
              <div class="menu-icon">
                <mat-icon>
                  {{
                    input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
                  }}
                </mat-icon>
              </div>
            </div>
          </div>
          <div class="bottom-border"></div>
          <mat-menu class="form-menu" #ItemMenu="matMenu">
            <div
              class="scroll"
              [infiniteScroll]="loadMore"
              [scrollRatio]="0.66"
              [initScrollThreshold]="100"
            >
              <div class="menu-holder">
                <div
                  *ngFor="let choice of input.filteredOptions; first as isFirst"
                  class="choice"
                  (click)="
                    input.property === 'deviceIds'
                      ? $event.stopPropagation()
                      : null
                  "
                >
                  <div
                    *ngIf="input.search_selector"
                    [class]="isFirst ? 'first-choice' : 'choice-button'"
                    (click)="selectProp(choice, input.property, i, input)"
                  >
                    <span>{{ choice }}</span>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
    </div>
    <div *ngIf="deviceIds.length && isForm" class="deviceId-list">
      <h2>{{ "manager.Devices.deviceList" | translate }}</h2>
      <div class="devices">
        <div class="device" *ngFor="let device of deviceIds; let i = index">
          <p>{{ device }}</p>
          <app-button
            class="button"
            iconLeft="close"
            (clicked)="deleteDevice(i)"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isForm && !isUploaded" class="buttons-form">
    <app-button
      class="button"
      [labelPath]="'misc.cancel' | translate"
      type="filled"
      (clicked)="triggerForm()"
    ></app-button>
    <app-button
      class="button"
      [labelPath]="'misc.apply' | translate"
      type="filled"
      (clicked)="updateDataFromForm()"
      [disabled]="!isFormValid"
    ></app-button>
  </div>
</div>
<app-file-uploader
  *ngIf="isUploaded"
  [datas]="uploadDatas"
  [properties]="properties[typeSelected]"
  [fileName]="fileName"
  [referenceSummary]="referenceSummary"
  uploaderType="reference"
  [columnNames]="columnNames"
  [separatorError]="separatorError"
  [separatorErrorWarnings]="separatorErrorWarnings"
  (goBack)="backOnList()"
  (doProvisioning)="doProvisioning($event)"
>
</app-file-uploader>

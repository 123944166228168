<div class="manager-manager">
  <div class="manager-sidenav">
    <div class="nav-holder">
      <div
        [ngClass]="navStyle"
        (click)="onNavChange(nav)"
        *ngFor="let nav of navObject; let i = index"
      >
        <div class="nav-selector" [ngClass]="nav.selectorStyle"></div>
        <app-button
          class="nav-button"
          [iconLeft]="nav.navLogo"
          (clicked)="onNavChange(nav)"
        ></app-button>
        <div class="nav-name">
          {{ "manager." + nav.navName + ".label" | translate }}
        </div>
      </div>
      <div
        [ngClass]="navStyle"
        (click)="onNavChange(tokenNavObject)"
        *ngIf="isIBMAdmin()"
      >
        <div
          class="nav-selector"
          [ngClass]="tokenNavObject.selectorStyle"
        ></div>
        <app-button
          class="nav-button"
          [iconLeft]="tokenNavObject.navLogo"
          (clicked)="onNavChange(tokenNavObject)"
        ></app-button>
        <div class="nav-name">
          {{ "manager." + tokenNavObject.navName + ".label" | translate }}
        </div>
      </div>
      <div [ngClass]="navStyle" (click)="onNavChange(switchNavObject)">
        <div
          class="nav-selector"
          [ngClass]="switchNavObject.selectorStyle"
        ></div>
        <app-button
          class="nav-button"
          [iconLeft]="switchNavObject.navLogo"
          (clicked)="onNavChange(switchNavObject)"
        ></app-button>
        <div class="nav-name">
          {{ "manager." + switchNavObject.navName + ".label" | translate }}
        </div>
      </div>
    </div>
  </div>
  <app-user-manager *ngIf="selectedNav === 'Users'"></app-user-manager>
  <app-profile-manager *ngIf="selectedNav === 'Groups'"></app-profile-manager>
  <app-reference-manager
    *ngIf="selectedNav === 'Reference'"
    [referenceLoading]="referenceLoading"
  ></app-reference-manager>
  <app-device-off-contract-manager
    *ngIf="selectedNav === 'Token' && isIBMAdmin()"
  ></app-device-off-contract-manager>
  <app-switch *ngIf="selectedNav === 'Devices'"></app-switch>
</div>

import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output
} from "@angular/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import {
  MatDialogConfig,
  MatDialogRef,
  MatDialog
} from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import {
  expectedColumnNames,
  UploadDialogData
} from "../upload-dialog/upload-dialog.typing";
import { ReferenceNameControllerService } from "../../../../services/reference-name-controller.service";
import { UploadDialogComponent } from "../upload-dialog/upload-dialog.component";
import * as FileSaver from "file-saver";
import { getDateDayMonthYear } from "../../../../services/format-date";
import { ReferenceSummary } from "../../../../models/reference-summary";
import { ContainerTypeControllerService } from "../../../../services/container-type-controller.service";
import { DeviceContainerControllerService } from "../../../../services/device-container-controller.service";
import { ProvisioningControllerService } from "../../../../services/provisionning-controller.service";
import { InfiniteScrollDirective } from "../../../../directives/infinite-scroll.directive";
import { SimpleDialogComponent } from "../../../../components/dialog/simple-dialog.component";
import { TranslateService } from "@ngx-translate/core";

export interface ChangeDevice {
  code: any;
  deviceId: string;
}

@Component({
  selector: "app-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"]
})
export class SwitchComponent implements OnInit {
  private readonly SWITCH_REFERENCE = "switch";
  public isForm: boolean = false;
  public dataLoading: boolean = false;
  public allDataFetched: boolean = false;

  private skip: number = 0;
  private limit: number = 100;
  private search: string;
  public searchValueUpdate = new Subject<string>();

  public deviceIds: string[] = [];
  private devices: any[] = [];
  private code: string;
  public isFormValid: boolean = false;
  public rejectedDevice: string[];
  public rejectedContainers: string[];
  public acceptedDevices: string[];
  public nonExistingCode: string[];
  public translationRejectedDevices: string;
  public translationAcceptedDevices: string;
  public translationRejectedContainers: string;
  public translationNonExistingCode: string;

  objectCreated: any = {};
  @Output() newObject: EventEmitter<any> = new EventEmitter();
  @Output() formIsValid: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  private readonly references: any = {
    Switch: this.SWITCH_REFERENCE
  };

  formConfig: any = {
    name: "Test",
    title: "test",
    inputs: [
      {
        property: "code",
        title: "code",
        type: "string",
        search_selector: true,
        choices: "",
        mandatory: true,
        visible: true
      },
      {
        property: "deviceIds",
        title: "deviceIds",
        type: "string",
        search_selector: true,
        choices: "",
        mandatory: true,
        visible: true
      }
    ]
  };

  private subscription: Subscription = new Subscription();
  private rawFile: string;
  private dialogRef: MatDialogRef<UploadDialogComponent>;

  public isUploaded: boolean = false;
  public uploadMode: "Change" = "Change";
  public typeSelected: string;
  public uploadDatas: any[];
  public fileName: string;
  public columnNames: string[];
  public referenceSummary: ReferenceSummary;
  public separatorError: boolean = false;
  public separatorErrorWarnings: string[] = [];

  selectedNav: string = "Information";

  public devicesList: any[] = [];

  public properties: any = {
    Switch: [{ property: "code" }, { property: "deviceId" }]
  };

  constructor(
    private referenceNameControllerService: ReferenceNameControllerService,
    public dialog: MatDialog,
    public containerTypeControllerService: ContainerTypeControllerService,
    public deviceContainerControllerService: DeviceContainerControllerService,
    public provisioningControllerService: ProvisioningControllerService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.searchValueUpdate
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((value) => {
        this.search = value;
        this.loadMore();
      });
  }

  loadFile(file: File) {
    if (file) {
      let reader: FileReader = new FileReader();
      this.uploadDatas = [];
      this.isUploaded = true;
      reader.readAsText(file);
      reader.onload = (_e) => {
        let csv: string = reader.result as string;
        this.rawFile = csv;
        this.fileName = file.name;
        this.parseCSVData(csv);
      };
    }
  }

  private parseCSVData(csv: string) {
    let separator: string;
    let dataLines: string[] = csv.split("\r\n");
    this.separatorError = false;
    this.separatorErrorWarnings = [];
    let dataLinesError: Array<[number, string]> = [];
    dataLines = dataLines.length === 1 ? csv.split("\n") : dataLines;
    const columnNamesColon: string[] = dataLines[0].split(",");
    const columnNamesSemiColon: string[] = dataLines[0].split(";");
    separator =
      columnNamesSemiColon.length >= columnNamesColon.length ? ";" : ",";
    this.columnNames = dataLines[0].split(separator);
    dataLines.shift();
    let uploadDatas: string[][] = [];
    let props: string[];
    let count = 1;
    for (const dataLine of dataLines) {
      count += 1;
      if (dataLine.length > this.properties[this.typeSelected].length * 2) {
        props = dataLine.split(separator);
        if (props.length !== this.columnNames.length) {
          this.separatorError = true;
          dataLinesError.push([count, dataLine]);
        }
        uploadDatas.push(props);
      }
    }
    this.uploadDatas = uploadDatas;
  }

  public backOnList() {
    this.typeSelected = undefined;
    this.isUploaded = false;
    this.isForm = false;
    this.nonExistingCode = undefined;
  }

  public doProvisioning() {
    this.updateContainerType(this.prepareDataFromCsv());
  }

  public triggerForm() {
    this.isForm = !this.isForm;
    if (this.isForm === true) {
      this.codeAndDevices();
    } else {
      this.deviceIds = [];
    }
  }

  private codeAndDevices() {
    for (const input of this.formConfig.inputs) {
      if (input.property === "code") {
        this.subscription.add(
          this.containerTypeControllerService
            .getContainerTypes()
            .subscribe((res) => {
              input.choices = res
                .map(({ code }) => {
                  return code;
                })
                .sort();
              input.filteredOptions = input.choices;
            })
        );
      } else if (input.property === "deviceIds") {
        this.subscription.add(
          this.deviceContainerControllerService
            .getDeviceIdsFilter(this.skip, this.limit, this.search)
            .subscribe((res) => {
              this.devices = res;
              input.choices = this.devices;
              input.filteredOptions = res;
            })
        );
      }
    }
  }

  saveFile(referenceNameId: string, text: string) {
    let concatWarnings = [];

    if (this.acceptedDevices) {
      this.translateService
        .get("manager.Reference.switch.acceptedDevices", {
          length: this.acceptedDevices.length
        })
        .subscribe((translation) => {
          this.translationAcceptedDevices = translation;
        });
    }
    if (this.rejectedDevice) {
      this.translateService
        .get("manager.Reference.switch.rejectedDevice", {
          length: this.rejectedDevice.length
        })
        .subscribe((translation) => {
          this.translationRejectedDevices = translation;
        });
      concatWarnings.push(this.translationRejectedDevices);
      concatWarnings.push(...this.rejectedDevice);
    }
    if (this.rejectedContainers) {
      this.translateService
        .get("manager.Reference.switch.rejectedContainers", {
          length: this.rejectedContainers.length
        })
        .subscribe((translation) => {
          this.translationRejectedContainers = translation;
        });
      concatWarnings.push(this.translationRejectedContainers);
      concatWarnings.push(...this.rejectedContainers);
    }

    if (this.nonExistingCode) {
      this.translateService
        .get("manager.Reference.switch.nonExistingCodes", {
          length: this.nonExistingCode.length
        })
        .subscribe((translation) => {
          this.translationNonExistingCode = translation;
        });
      concatWarnings.push(this.translationNonExistingCode);
      concatWarnings.push(...this.nonExistingCode);
    }

    this.subscription.add(
      this.referenceNameControllerService
        .createReferenceFile(
          { referenceNameId, uploadDate: Date.now() },
          text,
          this.uploadMode
        )
        .subscribe((_result) => {
          this.referenceSummary = new ReferenceSummary({});
          this.translateService
            .get("manager.Reference.info", { fileName: this.fileName })
            .subscribe((translation) => {
              if (this.acceptedDevices) {
                this.referenceSummary.info = [
                  translation,
                  this.translationAcceptedDevices,
                  ...this.acceptedDevices
                ];
              }
            });
          this.translateService
            .get("manager.Reference.detail", {
              uploadDatas: this.uploadDatas.length
            })
            .subscribe((translation) => {
              this.referenceSummary.details = [translation];
            });
          this.referenceSummary.warnings = concatWarnings;
        })
    );
  }

  public changeUploadMode(mode: "Change") {
    this.uploadMode = mode;
  }

  public downloadTemplate(referenceType: "Switch") {
    const csvContent = expectedColumnNames[referenceType].join(",") + "\r\n";
    const blob = new Blob([csvContent], { type: "text/csv" });
    FileSaver.saveAs(blob, `${referenceType}_${getDateDayMonthYear()}.csv`);
  }

  public async openDialog(referenceType: "Switch") {
    this.typeSelected = referenceType;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;

    this.dialogRef = this.dialog.open(UploadDialogComponent, {
      panelClass: "upload-dialog-container",
      autoFocus: false,
      data: {
        switchMode: true,
        previousRowNumber: undefined,
        loadFile: this.loadFile.bind(this),
        changeUploadMode: this.changeUploadMode.bind(this),
        referenceType: this.typeSelected
      } as UploadDialogData
    });
  }

  private updateContainerType(decodedData: any) {
    this.subscription.add(
      this.provisioningControllerService
        .checkContainers(Object.keys(decodedData.devices))
        .subscribe((res) => {
          if (!res) {
            this.subscription.add(
              this.provisioningControllerService
                .update(decodedData)
                .subscribe((res) => {
                  this.acceptedDevices = res.devicesIds;
                  this.rejectedDevice = res.rejectedDevice;
                  this.rejectedContainers = res.rejectedContainers;
                  if (
                    this.rejectedContainers.length == 0 &&
                    this.rejectedDevice.length == 0
                  ) {
                    let data = {};
                    this.translateService
                      .get("dialogSwitch")
                      .subscribe((translations) => {
                        data = {
                          title: translations.title,
                          text: translations.description,
                          yes: translations.confirm
                        };
                      });
                    const dialogRef = this.dialog.open(SimpleDialogComponent, {
                      data
                    });
                    this.subscription.add(
                      dialogRef.afterClosed().subscribe((isConfirmed) => {
                        if (isConfirmed) {
                          this.deviceIds = [];
                          this.triggerForm();
                          this.resetFormData();
                        }
                      })
                    );
                    const date: number = Date.now();
                    const userName: string = localStorage.getItem("username");
                    this.saveFile(
                      this.references[this.typeSelected],
                      this.rawFile
                    );
                    this.saveFile(
                      `reference:${userName}:${this.typeSelected}:${date}`,
                      this.rawFile
                    );
                  } else {
                    let data = {};
                    this.translateService
                      .get("dialogSwitchError")
                      .subscribe((translations) => {
                        data = {
                          title: translations.title,
                          text: translations.description,
                          yes: translations.confirm
                        };
                      });
                    const date: number = Date.now();
                    const userName: string = localStorage.getItem("username");
                    this.saveFile(
                      this.references[this.typeSelected],
                      this.rawFile
                    );
                    this.saveFile(
                      `reference:${userName}:${this.typeSelected}:${date}`,
                      this.rawFile
                    );
                    const dialogRef = this.dialog.open(SimpleDialogComponent, {
                      data
                    });
                    this.subscription.add(
                      dialogRef.afterClosed().subscribe((isConfirmed) => {
                        if (isConfirmed) {
                          this.isFormValid = false;
                          this.resetFormData();
                        }
                      })
                    );
                  }
                })
            );
          } else {
            let data = {};
            this.nonExistingCode = res;
            this.translateService.get("codeError").subscribe((translations) => {
              data = {
                title: translations.title,
                yes: translations.confirm
              };
            });
            this.translateService
              .get("codeError.description", {
                code: this.nonExistingCode.toString()
              })
              .subscribe((translation) => {
                data = {
                  ...data,
                  text: translation
                };
              });

            const date: number = Date.now();
            const userName: string = localStorage.getItem("username");
            this.saveFile(
              `reference:${userName}:${this.typeSelected}:${date}`,
              this.rawFile
            );
            const dialogRef = this.dialog.open(SimpleDialogComponent, {
              data
            });

            this.subscription.add(
              dialogRef.afterClosed().subscribe((isConfirmed) => {
                return isConfirmed;
              })
            );
          }
        })
    );
  }

  private resetFormData() {
    this.objectCreated = {};
    this.search = null;
    this.formConfig.inputs.forEach((input) => {
      input.value = null;
      input.filter = null;
    });
    this.codeAndDevices();
  }

  private prepareDataFromCsv() {
    let dataDecode: ChangeDevice[] = [];

    //on récupère les données du fichier
    this.rawFile.split("\n").map((line, index) => {
      if (line.length > 0 && index > 0) {
        let charToSplit = line.includes(";") ? ";" : ",";
        let [code, deviceId] = line.split(charToSplit);
        deviceId = deviceId.replace("\r", "");
        dataDecode.push({ code, deviceId });
      }
    });

    //cette fonction permet de rassembler tous les devices par container : {code: [deviceId1, deviceId2, ...]}
    const groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x.deviceId);
        return rv;
      }, {});
    };
    dataDecode.filter(this.onlyUnique);
    const devices = groupBy(dataDecode, "code");
    return { devices };
  }

  private prepareDataFromForm() {
    this.deviceIds = this.deviceIds.filter(this.onlyUnique);
    return { devices: { [this.code]: this.deviceIds } };
  }

  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  updateDataFromForm() {
    this.updateContainerType(this.prepareDataFromForm());
  }

  public loadMore = () => {
    for (const input of this.formConfig.inputs) {
      if (input.property === "deviceIds") {
        if (!this.dataLoading && !this.allDataFetched) {
          this.dataLoading = true;
          this.subscription.add(
            this.deviceContainerControllerService
              .getDeviceIdsFilter(this.skip, this.limit, this.search)
              .subscribe((res) => {
                input.choices = [...input.choices, ...res];
                input.filteredOptions = input.choices;
                this.devices = input.filteredOptions;
                this.skip += this.limit;
                this.dataLoading = false;
              })
          );
        }
      }
    }
  };

  public updateMatchDataSource(): string[] {
    return (this.devices = this.devices.filter(({ key }) => {
      if (typeof key === "string") {
        key = key.toUpperCase();
      } else if (typeof key === "number") {
        key = key.toString();
      }
      return key === this.search.toUpperCase();
    }));
  }

  changeArrow(input: any) {
    input.isOpen = input.isOpen ? false : true;
  }

  onFilterChange(input) {
    if (input.property !== "deviceIds") {
      input.filteredOptions = input.choices.filter((option) =>
        option.toLowerCase().includes(input.filter.toLowerCase())
      );
    } else {
      input.choices = [];
      this.skip = 0;
      this.limit = 100;
      this.search = input.filter.toUpperCase();
      this.searchValueUpdate.next(input.filter);
    }
  }

  selectProp(choice, prop, index, input?) {
    this.objectCreated[prop] = choice;
    if (input?.search_selector) {
      if (choice === "") {
        this.objectCreated[prop] = input.choices[0];
      }
    }
    if (input.property === "deviceIds") {
      this.deviceIds.push(choice);
    }
    if (input.property === "code") {
      input.filter = choice;
      this.code = choice;
    }
    this.onValidateForm(index);
  }

  onValidateForm(event) {
    // removal of unnecessary spaces (beginning / end of string and duplicates between words)

    this.objectCreated[this.formConfig.inputs[event].property] =
      this.objectCreated[this.formConfig.inputs[event].property].trim();

    this.formConfig.inputs[event].value =
      this.objectCreated[this.formConfig.inputs[event].property];

    this.formConfig.inputs[event].valid = this.verifyInput(
      this.formConfig.inputs[event]
    );

    this.formIsValid.emit(this.checkWholeForm());
    this.newObject.emit(this.objectCreated);
  }

  verifyInput(input: any): boolean {
    input.errorMsg = undefined;

    if (input.mandatory && this.objectCreated[input.property]) {
      if (input.validityConditions) {
        return this.verify(input);
      }
      return true;
    }
    if (!input.mandatory) {
      return true;
    }
    return false;
  }

  verify(input: any): boolean {
    let valid: boolean = true;
    let value = input.value;
    this.objectCreated[input.property] = value;
    for (const condition of input.validityConditions) {
      if (condition.type === "greater") {
        valid = Number(value) >= condition.value ? valid : false;
      }
      if (condition.type === "lower") {
        valid = Number(value) <= condition.value ? valid : false;
      }
      if (condition.type === "like") {
        valid = value.match(condition.value) ? valid : false;
      }
    }
    if (input.transform === "number") {
      this.objectCreated[input.property] = Number(value);
    }
    return valid;
  }

  checkWholeForm() {
    let isValid: boolean = true;
    for (const input of this.formConfig.inputs) {
      if (input.property === "code" && !input.value) {
        isValid = false;
      }
    }

    if (this.deviceIds.length === 0) {
      isValid = false;
    }
    this.isFormValid = isValid;
    return isValid;
  }

  private deleteDevice(index: number) {
    this.deviceIds.splice(index, 1);
    this.checkWholeForm();
  }
}

import { DeviceContainerDTO } from "../models/device-container";
import { environment } from "../../environments/environment";

import { HttpClientWrapper } from "./http-client.wrapper";
import { Injectable } from "@angular/core";
import { timeout } from "rxjs/operators";
import { Observable } from "rxjs";

/**
 * DeviceContainerController service call api rest for DeviceContainerDTO - return DeviceContainerDTO
 */
export class ProvisioningKPIByMonth {
  keys: Date[];
  provisioners: string[];
  assemblyNumber: number[][];
}
export class ProvisioningKPIByWeek {
  keys: string[];
  provisioners: string[];
  assemblyNumber: Array<number[]>;
}

export class DataProvisioner {
  data: number[];
}

export class ProvisioningDetail {
  keys: Date[];
  assemblyNumber: number[][];
}

export class ContainerByProvisionner {
  keys: string[];
  provisioners: string[];
  assemblyNumber: number[][];
}
export class ActiveDevices {
  keys: string[];
  assemblyNumber: number[][];
}

export class ProvisioningBySitePeriod {
  dateMin: number;
  dateMax: number;
  assemblyUser?: string;
}
export class ProvisioningBySite {
  keys: string[];
  provisioners: string[];
  assemblyNumber: number[][];
}

export class Errors {
  keys: string[];
  provisioners: string[];
  assemblyNumber: number[][];
}
export class StockByMonth {
  keys: string[];
  provisioners: string[];
  assemblyNumber: number[][];
}

interface ResponseWrapperPacked {
  deviceIds: string;
}

@Injectable()
export class DeviceContainerControllerService {
  private readonly TIMEOUT: number = 10000;

  constructor(
    public httpClientContainerByProvisioner: HttpClientWrapper<ContainerByProvisionner>,
    public httpClientProvisioningKPIMonth: HttpClientWrapper<ProvisioningKPIByMonth>,
    public httpClientProvisioningKPIWeek: HttpClientWrapper<ProvisioningKPIByWeek>,
    public httpClientProvisioningBySites: HttpClientWrapper<ProvisioningBySite>,
    public httpClientProvisioningDetail: HttpClientWrapper<ProvisioningDetail>,
    public httpClientDevicesPacked: HttpClientWrapper<ResponseWrapperPacked>,
    public httpClientProvisioningAssemblySites: HttpClientWrapper<string>,
    public httpClientTotalDeliveredDevices: HttpClientWrapper<Number>,
    public httpClientWrapper: HttpClientWrapper<DeviceContainerDTO>,
    public httpClientStockByMonth: HttpClientWrapper<StockByMonth>,
    public httpClientProvisioningError: HttpClientWrapper<Errors>,
    public httpClientTotals: HttpClientWrapper<ActiveDevices>,
    public httpClientArrayString: HttpClientWrapper<String>
  ) {}

  getDeviceContainers(): Observable<DeviceContainerDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "device-containers"
    );
  }

  getFilteredDeviceContainers(
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<DeviceContainerDTO[]> {
    let filter: any = {};

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "deviceId ASC";
    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}device-containers?filter=${JSON.stringify(
        filter
      )}`
    );
  }

  getActiveDevices(): Observable<ActiveDevices> {
    return this.httpClientTotals
      .getUnique(
        environment.apiBaseUrl + "device-containers/provisioning-totals"
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getTotalDeliveredDevices(): Observable<Number> {
    return this.httpClientTotalDeliveredDevices
      .getUnique(
        environment.apiBaseUrl + "device-containers/provisioning-totals"
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getProvisioningKpiByMonth(): Observable<ProvisioningKPIByMonth> {
    return this.httpClientProvisioningKPIMonth
      .getUnique(
        environment.apiBaseUrl + "device-containers/provisioning-kpis-months"
      )
      .pipe(timeout(this.TIMEOUT));
  }
  getProvisioningKpiByWeek(): Observable<ProvisioningKPIByWeek> {
    return this.httpClientProvisioningKPIWeek
      .getUnique(
        environment.apiBaseUrl + "device-containers/provisioning-kpis-weeks"
      )
      .pipe(timeout(this.TIMEOUT));
  }
  getAssemblySites(): Observable<string[]> {
    return this.httpClientProvisioningAssemblySites
      .get(environment.apiBaseUrl + "device-containers/assembly-user")
      .pipe(timeout(this.TIMEOUT));
  }

  getProvisioningBySitePeriod(
    selectedSitePeriod: ProvisioningBySitePeriod
  ): Observable<ProvisioningBySite> {
    return this.httpClientProvisioningBySites
      .postUnique(
        environment.apiBaseUrl + "device-containers/provisioning-by-site",
        JSON.stringify(selectedSitePeriod)
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getProvisioningErrors(
    selectedSitePeriod: ProvisioningBySitePeriod
  ): Observable<Errors> {
    return this.httpClientProvisioningError
      .postUnique(
        environment.apiBaseUrl + "device-containers/provisioning-errors",
        JSON.stringify(selectedSitePeriod)
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getContainerTypesByProvisoners(
    selectedSitePeriod: ProvisioningBySitePeriod
  ): Observable<ContainerByProvisionner> {
    return this.httpClientContainerByProvisioner
      .postUnique(
        environment.apiBaseUrl +
          "device-containers/provisioning-container-type-by-site",
        JSON.stringify(selectedSitePeriod)
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getProvisioningDetail(
    selectedSitePeriod: ProvisioningBySitePeriod
  ): Observable<ProvisioningDetail> {
    return this.httpClientProvisioningDetail
      .postUnique(
        environment.apiBaseUrl + "device-containers/provisioning-details",
        JSON.stringify(selectedSitePeriod)
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getStockByMonth(): Observable<StockByMonth> {
    return this.httpClientStockByMonth
      .getUnique(environment.apiBaseUrl + "device-containers/stock-by-months")
      .pipe(timeout(this.TIMEOUT));
  }

  getStockByAssemblySite(
    selectedSitePeriod: ProvisioningBySitePeriod
  ): Observable<StockByMonth> {
    return this.httpClientStockByMonth
      .postUnique(
        environment.apiBaseUrl + "device-containers/stock-by-assembly-site",
        JSON.stringify(selectedSitePeriod)
      )
      .pipe(timeout(this.TIMEOUT));
  }

  getAllDeviceIds(): Observable<ResponseWrapperPacked> {
    return this.httpClientDevicesPacked.getUnique(
      environment.apiBaseUrl + "device-containers/filter-deviceIds"
    );
  }

  getDeviceIdsFilter(
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<String[]> {
    let filter: any = {};

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "deviceId ASC";
    return this.httpClientArrayString.get(
      `${environment.apiBaseUrl}device-containers/deviceIds?filter=${JSON.stringify(
        filter
      )}`
    );
  }
}

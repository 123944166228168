<div class="incident-manager" *ngIf="!isAddIncident">
  <!-- Header Bar Labels -->
  <div class="bar-header">
    <div class="bar-title">
      {{ "incident.title" | translate }}
    </div>
    <div class="bar-description">
      {{ "incident.subtitle" | translate }}
    </div>
  </div>
  <!-- Page Content Main -->
  <div class="page-content">
    <app-manager-list
      (selectElement)="selectIncident($event)"
      (changePage)="changePageIncident($event)"
      (changeFilter)="changeFilterIncident($event)"
      [addElementEnabled]="false"
      [displayObjects]="incidentsList"
      [maxItems]="numberOfAllIncident"
      [properties]="incidentProperties"
    ></app-manager-list>
  </div>
</div>

<div class="body-main" *ngIf="isAddIncident">
  <!-- Header Bar Buttons -->
  <div class="title-sample-header">
    <div class="page-title-left" (click)="backToList()">
      <div class="path">
        {{ "incident.path" | translate }}
      </div>
      <div class="separator">/</div>
    </div>

    <div class="page-title-right">
      <div class="delete-image" (click)="deleteIncident()">
        <svg-icon
          name="bin"
          [svgStyle]="{
            fill: '#E40101',
            'height.px': 16,
            'width.px': 16
          }"
        ></svg-icon>
      </div>
      <div class="action-buttons">
        <div class="cancel-button" (click)="backToList()">
          <div class="text">
            {{ "misc.cancel" | translate }}
          </div>
        </div>
        <div class="save-button" (click)="saveChanges()">
          <div class="text">
            {{ "incident.form.validFormLabel." + updateModeLabel | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Page Content Detail -->
  <div class="page-content">
    <!-- Side Nav -->
    <div class="nav-section-list">
      <div
        class="navigator"
        (click)="onNavChange(nav)"
        *ngFor="let nav of navObject; let i = index"
      >
        <div [ngClass]="nav.selectorStyle">
          {{ "incident.navName." + nav.navName | translate }}
        </div>
      </div>
    </div>
    <!-- Nav Content -->
    <div class="main-section">
      <!-- Tab 1 -->
      <div [class]="selectedNav !== 'Information' ? 'hidden' : 'form-holder'">
        <div class="title-main-section">
          <div class="text-box">
            {{ "incident.information.title" | translate }}
          </div>
          <div class="bottom-border"></div>
        </div>
        <app-manager-form
          (newObject)="changeSelectedIncident($event)"
          (formIsValid)="isFormValid($event)"
          [formConfig]="formConfig"
          [update]="updateMode"
        ></app-manager-form>
      </div>
      <!-- Tab 2 -->
      <div [class]="selectedNav !== 'Device' ? 'hidden' : 'list-holder'">
        <div class="title-main-section">
          <div class="text-box">
            {{ "incident.device.title" | translate }}
          </div>
          <div class="bottom-border"></div>
        </div>
        <app-device
          (deletedAllDevices)="backToList($event)"
          [incident]="selectedIncident"
        >
        </app-device>
      </div>
      <!-- Tab 3 -->
      <div [class]="selectedNav !== 'Comment' ? 'hidden' : 'list-holder'">
        <div class="title-main-section">
          <div class="text-box">
            {{ "incident.comment.title" | translate }}
          </div>
          <div class="bottom-border"></div>
        </div>
        <app-comment
          [commentsList]="commentsList"
          [incident]="selectedIncident"
        ></app-comment>
      </div>
    </div>
  </div>
</div>

<app-manager-list
  (delete)="deleteDevices()"
  (selectAll)="selectAllDevices()"
  (selectElement)="isOpen($event)"
  (changePage)="changePageDevice($event)"
  (changeFilter)="changeFilterDevice($event)"
  [allCheck]="selectAll"
  [addElementEnabled]="false"
  [displayObjects]="deviceList"
  [maxItems]="numberOfAllDevice"
  [properties]="deviceProperties"
></app-manager-list>

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UploadDialogData, expectedColumnNames } from "./upload-dialog.typing";

type UploadDialogScreen = "ImportMode" | "Import" | "Validation" | "Error";

interface Choice {
  value: string;
  choices: string[];
}

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"]
})
export class UploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData
  ) {}
  @ViewChild("fileDropRef", { static: false }) fileDropElement: ElementRef;

  ngOnInit(): void {
    this.switchMode = this.data?.switchMode;
  }

  private macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

  private uploadDatas: string[][] = [];
  private columnNames: string[] = [];
  public switchMode?: boolean | undefined;

  public importMode?: string;
  public file?: File;
  public errorPathList: string[] = [];
  public errorLineList: number[] = [];
  public currentScreen: UploadDialogScreen = "ImportMode";
  public titlePath: string =
    "manager.Reference.uploadDialog.importModeScreen.title";
  public columnMatching: Choice[] = [];

  public resetFileData(): void {
    this.file = undefined;
    this.uploadDatas = [];
    this.columnNames = [];
    this.errorPathList = [];
    this.errorLineList = [];
    this.currentScreen = "Import";
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onImportModeNext(): void {
    this.currentScreen = "Import";
  }

  public onFileUploadNext(): void {
    this.currentScreen = "Validation";
  }

  public onValidationNext(): void {
    if (this.file) {
      this.data.loadFile(this.file);
      this.dialogRef.close();
    }
  }

  public onFileUploadGoBack(): void {
    this.importMode = undefined;
    this.resetFileData();
    this.currentScreen = "ImportMode";
  }

  public onValidationGoBack(): void {
    this.resetFileData();
    this.currentScreen = "Import";
  }

  public selectFile(files: FileList): void {
    if (files && files.length > 0) {
      this.getCSVData(files.item(0));
    }
  }

  public removeFile(): void {
    this.resetFileData();
  }

  public getCSVData(file: File): void {
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "csv") {
      this.addErrorPath(
        "manager.Reference.uploadDialog.fileUploadScreen.parsingError.invalidFileType"
      );
      this.file = file;
      return;
    }

    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const csv: string = reader.result as string;
      this.parseCSVData(csv);
      this.file = file;
    };
  }

  public addErrorPath(path: string, count?: number): void {
    if (this.currentScreen !== "Error") {
      this.currentScreen = "Error";
    }
    if (!this.errorPathList.includes(path)) {
      this.errorPathList.push(path);
    }
    if (count) this.errorLineList.push(count);
  }

  public areArraysIdentical(
    firstArray: string[],
    secondArray: string[]
  ): boolean {
    if (firstArray.length !== secondArray.length) {
      return false;
    }
    return firstArray.every((value, index) => value === secondArray[index]);
  }

  public containsForbiddenCharacter(row: string): boolean {
    const forbiddenCharacters: string[] = ["\r", "\n"];
    return forbiddenCharacters.some((char) => row.includes(char));
  }

  public areValidPropsFormat(props: string[]): boolean {
    if (props.some((prop) => prop === "" || prop == null)) {
      return false;
    }
    if (this.data.referenceType === "MacAddress") {
      return this.macAddressRegex.test(props[0]);
    }
    return true;
  }

  public parseCSVData(csv: string) {
    let separator: string;
    let dataLines: string[] = csv.split("\r\n");
    dataLines = dataLines.length === 1 ? csv.split("\n") : dataLines;
    const columnNamesColon: string[] = dataLines[0].split(",");
    const columnNamesSemiColon: string[] = dataLines[0].split(";");
    separator =
      columnNamesSemiColon.length >= columnNamesColon.length ? ";" : ",";
    const separatorRegex = new RegExp(
      separator + '(?=(?:(?:[^"]*"){2})*[^"]*$)'
    );
    this.columnNames = dataLines[0].split(separatorRegex);
    if (
      !this.areArraysIdentical(
        this.columnNames,
        expectedColumnNames[this.data.referenceType]
      )
    ) {
      this.addErrorPath(
        "manager.Reference.uploadDialog.fileUploadScreen.parsingError.invalidColumn"
      );
      return;
    }
    dataLines.shift();
    let props: string[];
    let count = 1;
    for (const dataLine of dataLines) {
      count += 1;
      if (this.containsForbiddenCharacter(dataLine)) {
        this.addErrorPath(
          "manager.Reference.uploadDialog.fileUploadScreen.parsingError.invalidCharacter",
          count
        );
      }
      if (dataLine.length) {
        props = dataLine.split(separatorRegex);
        if (!this.areValidPropsFormat(props)) {
          this.addErrorPath(
            "manager.Reference.uploadDialog.fileUploadScreen.parsingError.invalidPropsFormat",
            count
          );
        }
        if (props.length !== this.columnNames.length) {
          this.addErrorPath(
            "manager.Reference.uploadDialog.fileUploadScreen.parsingError.valueNumberMismatch",
            count
          );
        }
        this.uploadDatas.push(props);
      }
    }
  }

  public getTitlePath(): string {
    if (this.currentScreen === "ImportMode") {
      return "manager.Reference.uploadDialog.importModeScreen.title";
    }
    if (this.currentScreen === "Import" && this.importMode) {
      return this.importMode;
    }
    if (this.currentScreen === "Validation") {
      return "manager.Reference.uploadDialog.validationScreen.title";
    }
    if (this.currentScreen === "Error") {
      return "manager.Reference.uploadDialog.fileUploadScreen.parsingError.title";
    }
  }

  public getRowNumber(): number {
    return this.uploadDatas.length;
  }

  public getColumnNumber(): number {
    return this.uploadDatas.length ? this.uploadDatas[0].length : 0;
  }

  public getFileName(): string | undefined {
    return this.file?.name;
  }

  public getErrorLineList(): string {
    return this.errorLineList.length
      ? ` (${this.errorLineList.slice(0, 30).toString()})`
      : "";
  }

  public getImportModeDescription(): string {
    if (
      this.importMode ===
      "manager.Reference.uploadDialog.importModeScreen.replaceReferential.title"
    ) {
      return "manager.Reference.uploadDialog.fileUploadScreen.infoCallout.replaceReferential";
    } else if (
      this.importMode ===
      "manager.Reference.uploadDialog.importModeScreen.addFieldsToReferential.title"
    ) {
      return "manager.Reference.uploadDialog.fileUploadScreen.infoCallout.addFieldsToReferential";
    } else if (
      this.importMode ===
      "manager.Reference.uploadDialog.importModeScreen.changeFieldsToReferential.title"
    ) {
      return "manager.Reference.uploadDialog.fileUploadScreen.infoCallout.changeFieldsToReferential";
    }
    console.error("Invalid import mode");
    return "";
  }
}

export interface UploadDialogData {
  previousRowNumber?: number;
  loadFile: (file: File) => void;
  changeUploadMode: (mode: "Replace" | "Add" | "Change") => void;
  switchMode?: boolean;
  referenceType:
    | "Containertype"
    | "Site"
    | "Route"
    | "MacAddress"
    | "Hybrides"
    | "BFR_CU"
    | "Switch";
}

export const expectedColumnNames = {
  Containertype: ["code", "category", "label"],
  Site: ["cofor", "name", "country", "city", "cp", "address"],
  Route: ["cu", "emb", "cofor", "warningTransitTime"],
  MacAddress: ["address", "longitude", "latitude", "sideId", "precision"],
  Hybrides: ["vendeur", "exped", "hyb term", "hybryde"],
  BFR_CU: ["CODE BFP", "CU"],
  Switch: ["code", "deviceId"]
};

export class ReferenceSummary {
  info: string[];
  warnings: string[];
  details?: string[];
  step?: string;

  constructor(jsonObj: any) {
    this.info = jsonObj.info;
    this.warnings = jsonObj.warnings;
    this.details = jsonObj.details;
    this.step = jsonObj.step;
  }
}

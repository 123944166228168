import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { environment } from "../../environments/environment";
import {
  ReferenceNameCreation,
  ReferenceNameDTO
} from "../models/reference-name";
import { HttpClientWrapper } from "./http-client.wrapper";
import { MatchingDTO } from "../models/matching";

/**
 * SitePostalController service call api rest for PostalAddress - return PostalAddressDTO
 */

@Injectable()
export class ReferenceNameControllerService {
  public observer: Subscriber<boolean>;
  public isLoadingObservable: Observable<boolean | undefined> = new Observable(
    (observer) => {
      this.observer = observer;
      observer.next(undefined);
    }
  );

  constructor(
    public httpClientVoid: HttpClientWrapper<void>,
    public httpClientRef: HttpClientWrapper<ReferenceNameDTO>,
    public httpClientMatching: HttpClientWrapper<MatchingDTO>
  ) {}

  createReferenceFile(
    referenceName: ReferenceNameDTO,
    text: string,
    uploadMode: "Replace" | "Add" | "Change"
  ): Observable<void> {
    const referenceNameCreation: ReferenceNameCreation = {
      referenceName,
      text,
      uploadMode
    };
    return this.httpClientVoid.postUnique(
      environment.apiBaseUrl + "referenceNames",
      referenceNameCreation
    );
  }

  getLastRef(referenceType: string): Observable<ReferenceNameDTO> {
    return this.httpClientRef.getUnique(
      environment.apiBaseUrl + `referenceName/last/${referenceType}`
    );
  }

  getMatching(referenceType: string): Observable<MatchingDTO> {
    return this.httpClientMatching.getUnique(
      environment.apiBaseUrl + `referenceName/matching/${referenceType}`
    );
  }

  getIsLoading(): Observable<boolean | undefined> {
    return this.isLoadingObservable;
  }

  setIsLoading(isLoading: boolean | undefined) {
    this.observer.next(isLoading);
  }
}
